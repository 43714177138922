<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import addCustomer from "./addCustomer.vue";
import reportdet from "@/components/report/header";
import Swal from "sweetalert2";
import axios from "@/Axiosconfig";

export default {
  name: "contacts",
  page: {
    title: "Customers",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    addCustomer,
    reportdet,
  },
  data() {
    return {
      title: "Customers",
      items: [
        {
          text: "USER:" + JSON.parse(sessionStorage.user).username,
        },
        {
          text: "Customers",
          active: true,
        },
      ],
      modaltitle:'Add Customer',
      editmode:false,
      first_name: "Jane",
      last_name: "Doe",
      phone_number: "07000002",
      address:"",
      username: '',
      email: 'jane@doe.com',
      gender: 'Other',
      customerType: 'Individual',
      account_type:'Customers',
      customer_group:null,
      business: null,
      contactId: null,
      taxNumber: 'N/A',
      alternativeContact: '07000003',
      landline: 'N/A',
      creditLimit: null,
      designation:'Mr',
      contact_type:'Customers',
      orderData: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [1, 10, 25, 50, 100,500,1000,1500,2000],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        {
          key: "contact_id",
          label: "Contact ID",
          sortable: true,
        },
        {
          key: "business.name",
          label: "Business Name",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "user.email",
          label: "Email",
          sortable: true,
        },
        {
          key: "tax_number",
          label: "Tax Number",
          sortable: true,
        },
        {
          key: "credit_limit",
          label: "Credit Limit",
          sortable: true,
        },
        {
          key: "account_balance",
          label: "Account Balance",
          sortable: true,
        },
        {
          key: "advance_balance",
          label: "Advance Balance",
          sortable: true,
        },
        {
          key: "added_on",
          label: "Added On",
          sortable: true,
        },
        {
          key: "customer_group.group_name",
          label: "Customer Group",
          sortable: true,
        },
        {
          key: "addresses",
          label: "Address",
          sortable: true,
        },
        {
          key: "user.phone",
          label: "Mobile",
          sortable: true,
        },
        {
          key: "action",
          label: "Action",
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.orderData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.updatearray();
  },
  methods: {
    updatearray() {
      Swal.fire({
        title: "Please Wait !",
        html: "Loading data...",
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      axios
        .get(
          `contacts/?contact_type=${this.contact_type}&limit=${this.perPage}&offset=${
            (this.currentPage - 1) * this.perPage
          }`
        )
        .then((response) => {
          this.orderData = response.data["results"];
          Swal.close();
        })
        .catch((e) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "" + e,
            showConfirmButton: true,
          }).then((e) => {
            Swal.close(e);
          });
        });
    },

    printpdf(pl) {
      //console.log(this.tokenString);
      this.pl = pl;
      const data = this.orderData.map((row) => ({
        ID: row.id,
        Name: row.user.first_name,
      }));
      //get headers
      const headers = Object.keys(data[0]);
      const cars = [];
      Object.entries(data).forEach((val) => {
        const [key, value] = val;
        console.log(key, value);
        cars.push(Object.values(data[key]));
      });

      const uniqueCars = Array.from(new Set(cars));
      this.headers = headers;
      this.uniqueCars = uniqueCars;
      //alert(headers);
    },
    getrpt() {
      const d = new Date();
      const filename =
        d.getFullYear() +
        "-" +
        d.getMonth() +
        "-" +
        d.getDate() +
        "-" +
        d.getHours() +
        "-" +
        d.getMinutes() +
        "-" +
        d.getSeconds() +
        "-" +
        d.getMilliseconds();

      const data = this.orderData.map((row) => ({
        "Contact Id": row.contact_id,
        Name: `${row.user.first_name} ${row.user.last_name}`,
      }));

      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return '"' + escaped + '"';
        });
        csvRows.push(values.join(","));
      }
      const csvData = csvRows.join("\n");

      const blob = new Blob([csvData], { type: "textcsv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", this.title + filename + ".csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    edit(data){
      this.modaltitle='Update Customer'
      this.myid=data.item.id,
      this.editmode=true,
      this.myindex=data.index,
      this.contactId=data.item.contact_id;
      this.designation=data.item.designation;
      this.customer_group=data.item.customer_group;
      this.address=null;
      this.first_name=data.item.user.first_name;
      this.last_name=data.item.user.last_name;
      this.landline=data.item.landline;
      this.customerType=data.item.account_type;
      this.account_type=data.item.contact_type;
      this.business=data.item.business.name;
      this.taxNumber=data.item.tax_number;
      this.creditLimit=data.item.credit_limit;
      this.phone_number=data.item.user.phone;
      this.alternative_contact=data.item.alternative_contact;
    },
    deleterec(index, id) {
      const name = this.orderData[index].user.first_name;
      Swal.fire({
        title: "Are you sure, you want to delete? " + name,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete(`contacts/` + id + `/`)
            .then(() => {
              this.orderData.splice(index, 1);
              Swal.fire(
                name + " Deleted!",
                "Your record has been deleted.",
                "success"
              );
              Swal.close();
            })
            .catch((e) => {
              Swal.fire({
                position: "center",
                icon: "error",
                title: "" + e,
                showConfirmButton: true,
              }).then((e) => {
                Swal.close(e);
              });
            });
        }
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="card">
      <div class="card-body">
        <form @submit.prevent="handleSubmit">
          <div class="card">
            <div class="card-body">
              <div class="row justify-content-between">
                <div class="col-sm-6">
                  <button
                    class="btn btn-success waves-effect waves-light uil-export"
                    @click="getrpt()"
                  >
                    Export to CSV
                  </button>
                </div>

                <div class="col-sm-2">
                  <button
                    @click="printpdf()"
                    v-b-modal.modal-Print
                    class="btn btn-success waves-effect waves-light mdi-file-pdf"
                  >
                    Print PDF
                  </button>
                </div>
                <div class="col-sm-2">
                  <button
                    v-b-modal.modal-Add
                    class="btn btn-success waves-effect waves-light uil-focus-add"
                    @click="clearvalues()"
                  >
                    Add Customer
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body changebg">
                  <div class="row">
                    <div class="col-12">
                      <div>
                        <div class="float-end">
                          <form class="d-inline-flex mb-3"></form>
                        </div>
                      </div>
                      <div
                        class="table table-centered datatable dt-responsive nowrap table-card-list dataTable no-footer dtr-inline"
                      >
                        <div class="row">
                          <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_length" class="dataTables_length">
                              <label class="d-inline-flex align-items-center fw-normal">
                                Show&nbsp;
                                <b-form-select
                                  v-model="perPage"
                                  size="sm"
                                  :options="pageOptions"
                                  @input="updatearray()"
                                ></b-form-select
                                >&nbsp;entries
                              </label>
                            </div>
                          </div>
                          <!-- Search -->
                          <div class="col-sm-12 col-md-6">
                            <div
                              id="tickets-table_filter"
                              class="dataTables_filter text-md-end"
                            >
                              <label class="d-inline-flex align-items-center fw-normal">
                                Search:
                                <b-form-input
                                  v-model="filter"
                                  type="search"
                                  placeholder="Search..."
                                  class="form-control form-control-sm ms-2"
                                ></b-form-input>
                              </label>
                            </div>
                          </div>
                          <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                          <b-table class="table table-centered table-nowrap" :items="orderData" :fields="fields" responsive="sm"
                            :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                            :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                            <template v-slot:cell(credit_limit)="data">
                              <p class="my-2" v-if="data.item.credit_limit===null">No Limit</p>
                              <p class="my-2" v-else>{{ data.item.credit_limit }}</p>
                            </template>
                            <template v-slot:cell(account_balance)="data">
                              <p class="my-2">{{ `${data.accounts?data.item.accounts[0].account_balance:0.00}` }}</p>
                            </template>
                            <template v-slot:cell(advance_balance)="data">
                              <p class="my-2">{{ `${data.accounts?data.item.accounts[0].advance_balance:0.00}` }}</p>
                            </template>
                            <template v-slot:cell(name)="data">
                              <p class="my-2">{{ data.item.user.first_name }} {{ data.item.user.last_name }}</p>
                            </template>
                            <template v-slot:cell(action)="data">
                              <ul class="list-inline mb-0">
                                <b-dropdown class="list-inline-item" variant="white" right
                                  toggle-class="text-muted font-size-18 px-2">
                                  <template v-slot:button-content>
                                    <i class="uil uil-ellipsis-v"></i>
                                  </template>
                                  <router-link class="dropdown-item" :to="{
                                    name: 'update-profile',
                                    params: { email: data.item.user.email },
                                  }">Pay</router-link>
                                  <router-link class="dropdown-item" :to="{
                                    name: 'update-profile',
                                    params: { email: data.item.user.email },
                                  }">View</router-link>
                                  <a href="#" class="dropdown-item" v-b-modal.modal-Add @click="edit(data)">Edit</a>
                                  <a href="#" class="dropdown-item" @click="deleterec(data.index,data.item.id)">Deactivate</a>
                                  <hr />
                                  <router-link class="dropdown-item" :to="{
                                    name: 'update-profile',
                                    params: { email: data.item.user.email },
                                  }">Ledger</router-link>
                                  <router-link class="dropdown-item" :to="{
                                    name: 'update-profile',
                                    params: { email: data.item.user.email },
                                  }">Sales</router-link>
                                  <router-link class="dropdown-item" :to="{
                                    name: 'update-profile',
                                    params: { email: data.item.user.email },
                                  }">Documents & Notes</router-link>
                                </b-dropdown>
                              </ul>
                            </template>
                          </b-table>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div
                            class="dataTables_paginate paging_simple_numbers float-end"
                          >
                            <ul class="pagination pagination-rounded">
                              <!-- pagination -->
                              <b-pagination
                                v-model="currentPage"
                                :total-rows="rows"
                                :per-page="perPage"
                              ></b-pagination>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <b-modal id="modal-Print" title="Print PDF" hide-footer size="bg" centered>
      <reportdet
        :title="title"
        :orderData="orderData"
        :pl="pl"
        :headers="headers"
        :uniqueCars="uniqueCars"
        :shome="showme"
        v-show="showme"
      ></reportdet>
    </b-modal>
    <b-modal id="modal-Add" :title="modaltitle" size="xl" centered>
      <addCustomer
        :id="myid"
        :address="address"
        :myindex="myindex"
        :editmode="editmode"
        :modaltitle="modaltitle"
        :first_name="first_name"
        :last_name="last_name"
        :phone_number="phone_number"
        :email="email"
        :gender="gender"
        :customerType="customerType"
        :account_type="account_type"
        :customer_group="customer_group"
        :business="business"
        :contactId="contactId"
        :taxNumber="taxNumber"
        :alternativeContact="alternativeContact"
        :landline="landline"
        :creditLimit="creditLimit"
        :designation="designation"
      ></addCustomer>
    </b-modal>
  </Layout>
</template>

<style>
.changebg {
  background-color: #f7f6ebfb;
}
</style>
